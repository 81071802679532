import React, { useEffect, useState } from 'react';
import BoxDark from './components/BoxDark';
import MainLayout from '../../../layout/MainLayout';
import RightBar from './components/RightBar'



const Dashboard = () => {
    useEffect(() => {
        document.getElementById('list').addEventListener('click', () => setState(true));
        document.getElementById('list').addEventListener('dblclick', () => setState(false));
    }, [])
    const [state, setState] = useState(false);

    return (
        <>
            <MainLayout>
                <div className="outer">
                    <div className="row g-4">
                        <div className="col-lg-6" >
                            <BoxDark title="Total Locations" text="16" />
                        </div>
                        <div className="col-lg-6">
                            <BoxDark title="Total Active Screens" text="54" />
                        </div>
                        <div className="col-lg-6" id="list">
                            <BoxDark title="Offline Screens" text="2" />
                        </div>
                        <div className="col-lg-6">
                            <BoxDark title="Total Number of Channels" text="12" />
                        </div>
                    </div>
                </div>
                <RightBar title={state ? "Offline Screens" : null} text={state ? "Hopyard Bar and Grill - Pleasanton CA 1" : null} text2={state ? "Hopyard Bar and Grill - Pleasanton CA 1" : null} />
            </MainLayout>

        </>
    )
};

export default Dashboard;
