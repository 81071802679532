import React, { useState, useEffect } from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import { NavLink } from 'react-router-dom';


const MediaLibraryApps = () => {
    const [status, setState] = useState(false);
    useEffect(() => {
        document.getElementById('open').addEventListener('click', () => setState(true));
        document.getElementById('close').addEventListener('click', () => setState(false));
    }, []);

    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <div className="card-list">
                    <div className="outer-contact">
                        <h3>
                            Apps
                        </h3>

                    </div>

                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    Facebook
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                <div className="accordion-body">
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="" className="img-fluid" />
                                        <p>
                                            @internationalThrillerWriters
                                        </p>
                                    </div>
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="" className="img-fluid" />
                                        <p>
                                            @saucedbbq&spirits
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                    Weather
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                <div className="accordion-body">
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" className="img-fluid" />
                                        <p>
                                            San Francisco
                                        </p>
                                    </div>
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" className="img-fluid" />
                                        <p>
                                            New York
                                        </p>
                                    </div>
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" className="img-fluid" />
                                        <p>
                                            Seattle
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    Youtube
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                                <div className="accordion-body">
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_youtube.svg" alt="" className="img-fluid" />
                                        <p>
                                            Rick Steves Holyland Tour
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="rightBar order-first order-lg-last">
                    <div className="title-url" >
                        <img src="../../../../assets/images/icon_add.svg" alt="" className="img-fluid" />
                        <h3>
                            Add New
                        </h3>
                    </div>

                    <div className="linking-icon">
                        <div className="row gx-4">
                            <h3>Apps</h3>
                            <div className="col-lg-4 col-6">
                                <div className="first" id="open">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="facebook" className="img-fluid" />
                                        <p>
                                            Facebook
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="/media-library-apps-3">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="icon_weather" className="img-fluid" />
                                        <p>
                                            Weather
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="">
                                        <img src="../../../../assets/images/icon_youtube.svg" alt="icon_youtube" className="img-fluid" />
                                        <p>
                                            Youtube
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="">
                                        <img src="../../../../assets/images/icon_instagram@2x.png" alt="icon_instagram@2x" className="img-fluid" />
                                        <p>
                                            Instagram
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="">
                                        <img src="../../../../assets/images/icon_twitter.svg" alt="icon_twitter" className="img-fluid" />
                                        <p>
                                            Twitter
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="/media-library-app-store">
                                        {/* <img src="../../../../assets/images/icon_apps (1).svg" alt="" /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                            <path id="icon_apps" d="M24,24H18V18h6v6Zm-9,0H9V18h6v6ZM6,24H0V18H6v6Zm18-9H18V9h6v6Zm-9,0H9V9h6v6ZM6,15H0V9H6v6ZM24,6H18V0h6V6ZM15,6H9V0h6V6ZM6,6H0V0H6V6Z" transform="translate(0 0)" fill="#E4E4ED" />
                                        </svg>
                                        <p>
                                            App Store
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={status ? "click-left-bar" : "close-left-bar"}>
                        <h3>Facebook Settings</h3>
                        <form action="submitForm">
                            <div className="form-bg-dark">
                                <label htmlFor="name">
                                    Name
                                </label>
                                <input type="text" />
                                <label htmlFor="name">
                                    ID
                                </label>
                                <input type="email" placeholder="@facebookID" />
                            </div>
                            <div className="save-id-btn">
                                <button type="submit" className="me-2">
                                    Cancel
                                </button>
                                <NavLink to="#" className="theme-button-2" form="submitForm">
                                    Add
                                </NavLink>
                            </div>
                        </form>
                        <div className="close-btn" id="close">
                            <img src="../../../../assets/images/icon_close.svg" alt="" />
                        </div>

                    </div>
                </div>



            </MainLayout>
        </>
    )
};

export default MediaLibraryApps;
