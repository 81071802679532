import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import RightBarSelect from './components/RightBarSelect';
import { NavLink } from 'react-router-dom';



const MediaLibraryNewFolder = () => {
  return (
    <>
      <MainLayout>
        <LeftSidebar />
        {/* <PicturesTable /> */}
        <div className="card-list">

          <div className="outer-contact">
            <h3>
              Pictures
            </h3>
            <div className="card-icon">

              <span>
                View
              </span>
              <img src="../../../../../assets/images/icon_icon view.svg" alt="" />
              <img src="../../../../../assets/images/icon_list view_active.svg" alt="" className="align-icon" />
              <span>
                Sort by
              </span>
              <span className="change-color">
                Name
              </span>
              <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <NavLink to="#" className="dropdown-item">Action</NavLink>

                  </li>
                  <li>
                    <NavLink to="#" className="dropdown-item">Another action</NavLink>

                  </li>
                  <li>
                    <NavLink to="#" className="dropdown-item">Something else here</NavLink>

                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div className="list-table">
            <table class="table">

              <thead>
                <tr className="first-child">
                  <th>Name</th>
                  <th>Modified</th>
                  <th>Dimensions</th>
                  <th>Tog</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                    <span className="px-2">New Folder</span>
                  </td>
                  <td>--</td>
                  <td>--</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                    <span className="px-2">Restaurant Food</span>
                  </td>
                  <td>--</td>
                  <td>--</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                    <span className="px-2">
                      Portrait Photos
                    </span>
                  </td>
                  <td>--</td>
                  <td>--</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                    <span className="px-2">
                      Photo 1.jpg
                    </span>
                  </td>
                  <td>
                    05/12/2022 10:16PM
                  </td>
                  <td>1920*1080</td>
                  <td>Landscape</td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                    <span className="px-2">
                      Photo 1.jpg
                    </span>
                  </td>
                  <td>
                    05/12/2022 10:16PM
                  </td>
                  <td>1920*1080</td>
                  <td>Landscape</td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                    <span className="px-2">
                      Photo 1.jpg
                    </span>
                  </td>
                  <td>
                    05/12/2022 10:16PM
                  </td>
                  <td>1920*1080</td>
                  <td>Landscape</td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                    <span className="px-2">
                      Photo 1.jpg
                    </span>
                  </td>
                  <td>
                    05/12/2022 10:16PM
                  </td>
                  <td>1920*1080</td>
                  <td>Landscape</td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                    <span className="px-2">
                      Photo 1.jpg
                    </span>
                  </td>
                  <td>
                    05/12/2022 10:16PM
                  </td>
                  <td>1920*1080</td>
                  <td>Landscape</td>
                </tr>
                <tr>
                  <td>
                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                    <span className="px-2">
                      Photo 1.jpg
                    </span>
                  </td>
                  <td>
                    05/12/2022 10:16PM
                  </td>
                  <td>1920*1080</td>
                  <td>Landscape</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <RightBarSelect href="/multi-select" heading="Upload Pictures" />
      </MainLayout>
    </>
  )
};

export default MediaLibraryNewFolder;
