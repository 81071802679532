import React from 'react';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import MainLayout from '../../../layout/MainLayout';
import RightBarSelect from './components/RightBarSelect';



const MediaLibrary = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Pictures" />
                <RightBarSelect href="/media-library-pictures-detail" heading="Upload Pictures" />

            </MainLayout>

        </>
    )
};

export default MediaLibrary;
