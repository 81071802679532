import React from 'react';
import { NavLink } from "react-router-dom";


const Header = (props) => {
    return (
        <>
            <header>
                <div className="top-header">
                    <div className="listing">
                        <img src="../../assets/images/PJLogo-Master@2x.png" alt="" />
                        <div className="menu">
                            <input type="checkbox" id="box" />
                            <label htmlFor="box" className="click">
                                <i class="fa fa-align-justify"></i>
                            </label>
                            <ul>
                                <li >
                                    <NavLink to="/" className="active">Digital Signage</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Host">Host</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Advertiser">Advertiser</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="sing-log-in">

                        <div className="search-bar">
                            <input type="search" className="theme-input-1" placeholder="search" />
                            <img src="../../assets/images/Icon_search.svg" alt="" />
                        </div>
                        <div className="bell">
                            <img src="../../assets/images/icon_notification.svg" alt="notification" className="img-fluid" />
                        </div>
                        <img src="../../assets/images/icon_profile.svg" alt="icon_profile"
                            className="img-fluid" />
                        <div className="dropdown">
                            <NavLink to="#" className="btn  dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            </NavLink>

                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li>
                                    <NavLink to="#" className="dropdown-item">Account Settings</NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item item-2">
                                        <img src="../../assets/images/united-states-of-america.png" alt="" />
                                        English (US)
                                        <div className="dropdown dropdown-2">
                                            <NavLink to="#" className="btn  dropdown-toggle raute" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            </NavLink>
                                            <ul className="dropdown-menu menu-2" aria-labelledby="dropdownMenuLink">
                                                <li>
                                                    <NavLink to="#" className="dropdown-item">
                                                        <img src="../../assets/images/united-states-of-america.png" alt="" />
                                                        English (US)</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="#" className="dropdown-item">
                                                        <img src="../../assets/images/united-kingdom.png" alt="" className="img-fluid" />
                                                        English (Uk)
                                                    </NavLink>


                                                </li>
                                                <li>
                                                    <NavLink to="#" className="dropdown-item">
                                                        <img src="../../assets/images/china.png" alt="" />
                                                        日本語</NavLink>

                                                </li>
                                                <li>
                                                    <NavLink to="#" className="dropdown-item">
                                                        <img src="../../assets/images/european-union.png" alt="" />
                                                        Español (MEX)</NavLink>

                                                </li>
                                            </ul>
                                        </div>
                                    </NavLink>


                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">Log Out</NavLink>

                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </header>
        </>
    )
};

export default Header;
