import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import { NavLink } from 'react-router-dom';


const Move = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Pictures" img="../../../../assets/images/Icon_down.svg" title2="Portrait Photos" />
                <div className="rightBar order-first order-lg-last">
                    <h3>
                        3 Files
                    </h3>
                    <div className="select">
                        <label htmlFor="FName">
                            <img src="../../../../assets/images/icon_move.svg" alt="icon_move" />
                            Move
                        </label>
                        <div className="select-move">
                            <span>Move to</span>
                            {/* <div class="dropdown">
                                <button class="btn" type="button" id="drop" data-bs-toggle="dropdown" aria-expanded="false">
                                    Pictures
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="drop">
                                    <li> <NavLink to="#" className="dropdown-item">
                                        Restaurant Food</NavLink>
                                    </li>
                                    <li> <NavLink to="#" className="dropdown-item">
                                        Pictures</NavLink>
                                    </li>


                                </ul>
                            </div> */}
                            <div className="form-bg-dark">
                                <select className="form-select">
                                    <option selected>Pictures</option>
                                    <option value="1">Restaurant Food</option>
                                    <option value="2">Pictures</option>
                                </select>
                            </div>
                        </div>
                        <label htmlFor="copy">
                            <img src="../../../../assets/images/icon_copy.svg" alt="icon_copy.svg" />
                            Copy
                        </label>
                        <label htmlFor="Folder">
                            <img src="../../../../assets/images/icon_new folder.svg" alt="icon_new folder" />
                            New folder with selection
                        </label>
                        <div className="input-add">
                            <input type="text" class="form-control" placeholder="Add Tags" />
                            <img src="../../../../assets/images/icon_tag.svg" alt="" className="tag" />
                            <img src="../../../../assets/images/btn_tick_green.svg" alt="btn_tick_green" className="btn_tick_green" />
                        </div>

                    </div>
                    <div className="check-btn">
                        <button className="theme-3 ">
                            Cancel
                        </button>
                        <NavLink to="/add-tag" className="theme-4 mx-3 move-btn"> Move</NavLink>

                    </div>
                </div>
            </MainLayout>
        </>
    )
};

export default Move;
