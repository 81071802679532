import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const PicturesTable = (props) => {
    return (
        <>
            <div className="card-list ">

                <div className="outer-contact">
                    <div className="outer-pictures">
                        <h3>
                            {props.title}
                        </h3>
                        <img src={props.img} alt="" className="d-none d-md-block" />
                        <h3 className="d-none d-md-block">
                            {props.title2}
                        </h3>
                    </div>
                    <div className="card-icon">

                        <span>
                            View
                        </span>
                        <a href="/media-library-grid-view">
                            <img src="../../../../../assets/images/icon_icon view.svg" alt="" />
                        </a>
                        <img src="../../../../../assets/images/icon_list view_active.svg" alt="" className="align-icon" />

                        <span>
                            Sort by
                        </span>

                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>
                                    Name
                                </span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link to="#" className="dropdown-item bounder-top">
                                        Action
                                    </Link>


                                </li>
                                <li>

                                    <Link to="#" className="dropdown-item">
                                        Another action
                                    </Link>


                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item bounder-bottom">
                                        Something else here
                                    </Link>


                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="list-table">
                    <table class="table">

                        <thead>
                            <tr className="first-child">
                                <th>Name</th>
                                <th>Modified</th>
                                <th>Dimensions</th>
                                <th>Tog</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                    <span className="px-2">Restaurant Food</span>
                                </td>
                                <td>--</td>
                                <td>--</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                    <span className="px-2">
                                        Portrait Photos
                                    </span>
                                </td>
                                <td>--</td>
                                <td>--</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                                    <span className="px-2">
                                        Photo 1.jpg
                                    </span>
                                </td>
                                <td>
                                    05/12/2022 10:16PM
                                </td>
                                <td>1920*1080</td>
                                <td>Landscape</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                                    <span className="px-2">
                                        Photo 1.jpg
                                    </span>
                                </td>
                                <td>
                                    05/12/2022 10:16PM
                                </td>
                                <td>1920*1080</td>
                                <td>Landscape</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                                    <span className="px-2">
                                        Photo 1.jpg
                                    </span>
                                </td>
                                <td>
                                    05/12/2022 10:16PM
                                </td>
                                <td>1920*1080</td>
                                <td>Landscape</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                                    <span className="px-2">
                                        Photo 1.jpg
                                    </span>
                                </td>
                                <td>
                                    05/12/2022 10:16PM
                                </td>
                                <td>1920*1080</td>
                                <td>Landscape</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                                    <span className="px-2">
                                        Photo 1.jpg
                                    </span>
                                </td>
                                <td>
                                    05/12/2022 10:16PM
                                </td>
                                <td>1920*1080</td>
                                <td>Landscape</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/icon_image.svg" alt="icon_image" />
                                    <span className="px-2">
                                        Photo 1.jpg
                                    </span>
                                </td>
                                <td>
                                    05/12/2022 10:16PM
                                </td>
                                <td>1920*1080</td>
                                <td>Landscape</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>




        </>
    )
};

export default PicturesTable;
