import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import RightBar from './components/RightBar';
import TabScreen from './components/TabScreen';



const Screens1 = () => {
    return (
        <>
            <MainLayout>
                <div className="screen-front">
                    <TabScreen />

                    <RightBar />
                </div>
            </MainLayout>
        </>
    )
};

export default Screens1;