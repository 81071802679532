import React from 'react';
import MainLayout from '../../../layout/MainLayout';


const MediaPod1emptystate = () => {
    return (
        <>
            <MainLayout>
                <div className="media-page">
                    <div className="left_bar_media">
                        <p>You don’t have any Media Pod yet.</p>
                        <a href="/media-pod-2" className="theme-button-2">
                            Create Media Pod
                        </a>
                    </div>
                    <div className="screen-media">

                        <div className="row align-items-center">
                            <h3>
                                How It Works
                            </h3>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="media-heading outer-media">
                                    <img src="../../../../assets/images/img_playlist.svg" alt="mg_playlist" className="img-fluid" />
                                    <h4>
                                        Create a Media Pod
                                    </h4>
                                    <h5>
                                        1
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="media-heading">
                                    <img src="../../../../assets/images/img_schedule.svg" alt="img_schedule" className="img-fluid" />
                                    <h4>
                                        Add to Program
                                    </h4>
                                    <h5>
                                        2
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="media-heading">
                                    <img src="../../../../assets/images/img_deploy.svg" alt="img_deploy" className="img-fluid" />
                                    <div className="text-3">
                                        <h4>
                                            Deploy to Screens
                                        </h4>
                                        <h5>
                                            3
                                        </h5>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </MainLayout>
        </>
    )
};

export default MediaPod1emptystate;
