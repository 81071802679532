import React, { useState, useEffect } from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import { NavLink } from 'react-router-dom';


const MediaLibraryAppStore = () => {
    const [status, setState] = useState(false);
    useEffect(() => {
        document.getElementById('open').addEventListener('click', () => setState(true));
        document.getElementById('close').addEventListener('click', () => setState(false));
    }, []);

    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <div className="card-list">
                    <div className="outer-contact">
                        <h3>
                            App Store
                        </h3>

                    </div>
                    <div className="list-icon-social-media">
                        <div className="row gx-0">
                            <div className="col-lg-2 col-6">
                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="" />
                                        <h5>Facebook</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-lg-2 col-6">
                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" />
                                        <h5>Weather</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-lg-2 col-6">
                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_youtube.svg" alt="" />
                                        <h5>Youtube</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-2 col-6">

                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_instagram@2x.png" alt="" />
                                        <h5>Instagram</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-2 col-6">

                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_twitter.svg" alt="" />
                                        <h5>Twitter</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-2 col-6">

                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_yelp.svg" alt="" />
                                        <h5>PYelp</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-2 col-6">

                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/Pinterest.svg" alt="" />
                                        <h5>Pinterest</h5>
                                        <p>
                                            $ 2.99 / Month
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-2 col-6">

                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_google map.svg" alt="" />
                                        <h5>Google Map</h5>
                                        <p>
                                            $ 1.99 / Month
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-2 col-6">

                                <div className="social-icon">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_cnn.svg" alt="" />
                                        <h5>CNN</h5>
                                        <p>
                                            Free
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="add-store">

                        <NavLink to="/media-library-pictures-detail-v2" className="theme-button-2">
                            Add to Apps
                        </NavLink>
                    </div>

                </div>

                <div className="rightBar order-first order-lg-last">
                    <div className="title-url" >
                        <img src="../../../../assets/images/icon_add.svg" alt="" className="img-fluid" />
                        <h3>
                            Add New
                        </h3>

                    </div>

                    <div className="linking-icon">
                        <div className="row">
                            <h3>Apps</h3>
                            <div className="col-lg-4 col-6">
                                <div className="first" >
                                    <NavLink to="/media-library-apps">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="facebook" className="img-fluid" />
                                        <p>
                                            Facebook
                                        </p>

                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first" id="open">
                                    <NavLink to="/media-library-apps-3">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="icon_weather" className="img-fluid" />
                                        <p>
                                            Weather
                                        </p>
                                    </NavLink>


                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="">
                                        <img src="../../../../assets/images/icon_youtube.svg" alt="icon_youtube" className="img-fluid" />
                                        <p>
                                            Youtube
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="">
                                        <img src="../../../../assets/images/icon_instagram@2x.png" alt="icon_instagram@2x" className="img-fluid" />
                                        <p>
                                            Instagram
                                        </p>
                                    </NavLink>


                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="">
                                        <img src="../../../../assets/images/icon_twitter.svg" alt="icon_twitter" className="img-fluid" />
                                        <p>
                                            Twitter
                                        </p>
                                    </NavLink>


                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="#">
                                        {/* <img src="../../../../assets/images/icon_apps (1).svg" alt="" /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                            <path id="icon_apps" d="M24,24H18V18h6v6Zm-9,0H9V18h6v6ZM6,24H0V18H6v6Zm18-9H18V9h6v6Zm-9,0H9V9h6v6ZM6,15H0V9H6v6ZM24,6H18V0h6V6ZM15,6H9V0h6V6ZM6,6H0V0H6V6Z" transform="translate(0 0)" fill="#E4E4ED" />
                                        </svg>
                                        <p>
                                            App Store
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={status ? "click-left-bar" : "close-left-bar"}>
                        <h3>Facebook Settings</h3>
                        <div className="form-bg-dark">
                            <label htmlFor="name">
                                City
                            </label>
                            <select>
                                <option value="1"></option>
                            </select>
                            <div class="btn-group">
                                <button class="theme-cold">°C</button>
                                <button class="theme-button-2">°F</button>
                            </div>

                        </div>
                        <div className="save-id-btn">
                            <button>
                                Cancel
                            </button>
                            <a href="#" className="theme-button-2">
                                Add
                            </a>
                        </div>
                        <div className="close-btn" id="close">
                            <i class="fa fa-times"></i>
                        </div>

                    </div>
                </div>



            </MainLayout>
        </>
    )
};

export default MediaLibraryAppStore;
