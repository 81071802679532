import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import { NavLink } from 'react-router-dom';

const Upload2 = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Pictures" />
                <div className="rightBar order-first order-lg-last">
                    <h3>
                        <img src="../../../../assets/images/icon_upload_grey.svg" alt="icon_upload_grey" className="pe-2" />
                        Upload Pictures
                    </h3>
                    <div className="upload-box">
                        <p>Drag and drop your files here <br />or</p>
                        {/* Button trigger modal */}
                        <NavLink to="#" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className="theme-button-2">
                            Select
                        </NavLink>

                    </div>

                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Upload</h5>
                                </div>
                                <div className="modal-body">
                                    <ul className="list-outer-modal-1">
                                        <li>
                                            <i class="fa fa-clock-o" ></i>
                                            Recently Added
                                        </li>
                                        <li>
                                            <i class="fa fa-television"></i>
                                            My Computer
                                        </li>
                                        <li>
                                            <i class="fa fa-dropbox"></i>
                                            Dropbox
                                        </li>
                                        <li>
                                            <i class="fa fa-hdd-o"></i>
                                            Google Drive
                                        </li>
                                        <li>
                                            <i class="fa fa-hdd-o" ></i>
                                            OneDrive
                                        </li>
                                    </ul>
                                    <ul className="list-outer-modal-1">
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                                <path id="icon_folder" d="M8,0H2A2,2,0,0,0,.01,2L0,14a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V4a2.006,2.006,0,0,0-2-2H10L8,0Z" fill="#8B879E" />
                                            </svg>
                                            Download
                                        </li>
                                    </ul>
                                    <ul className="list-outer-modal-2">
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path id="icon_image" d="M17.778,20H2.222A2.225,2.225,0,0,1,0,17.778V2.222A2.225,2.225,0,0,1,2.222,0H17.778A2.225,2.225,0,0,1,20,2.222V17.778A2.225,2.225,0,0,1,17.778,20ZM6.111,11.667l-3.889,5H17.778l-5-6.667L8.889,15.011Z" transform="translate(0)" fill="#8B879E" />
                                            </svg>
                                            Photo 1
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path id="icon_image" d="M17.778,20H2.222A2.225,2.225,0,0,1,0,17.778V2.222A2.225,2.225,0,0,1,2.222,0H17.778A2.225,2.225,0,0,1,20,2.222V17.778A2.225,2.225,0,0,1,17.778,20ZM6.111,11.667l-3.889,5H17.778l-5-6.667L8.889,15.011Z" transform="translate(0)" fill="#8B879E" />
                                            </svg>
                                            Photo 1
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path id="icon_image" d="M17.778,20H2.222A2.225,2.225,0,0,1,0,17.778V2.222A2.225,2.225,0,0,1,2.222,0H17.778A2.225,2.225,0,0,1,20,2.222V17.778A2.225,2.225,0,0,1,17.778,20ZM6.111,11.667l-3.889,5H17.778l-5-6.667L8.889,15.011Z" transform="translate(0)" fill="#8B879E" />
                                            </svg>
                                            Photo 1
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path id="icon_image" d="M17.778,20H2.222A2.225,2.225,0,0,1,0,17.778V2.222A2.225,2.225,0,0,1,2.222,0H17.778A2.225,2.225,0,0,1,20,2.222V17.778A2.225,2.225,0,0,1,17.778,20ZM6.111,11.667l-3.889,5H17.778l-5-6.667L8.889,15.011Z" transform="translate(0)" fill="#8B879E" />
                                            </svg>
                                            Photo 1
                                        </li>
                                    </ul>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" data-bs-dismiss="modal" className="cancel">Cancel</button>
                                    <button type="button" className="theme-button-2">Upload (2)</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="choose-file">
                        <img src="../../../../assets/images/icon_new folder.svg" alt="icon_new folder" className="pe-2" />
                        New Folder
                    </button>

                    <div className="check-btn">
                        <div className="pro-gress">
                            <p className="upload-text">Uploading 2 files… (1/2)</p>
                            <NavLink to="/upload-3" className="button">
                                Cancel
                            </NavLink>

                        </div>
                        <div className="progress">
                            <div className="progress-bar w-25" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                </div>



            </MainLayout>
        </>
    )
};

export default Upload2;
