import React from 'react';
import Power from './Power';
import { Link, NavLink } from "react-router-dom";

const TabScreen = () => {
    return (
        <>
            <div className="screens">
                <div className="screen_title">
                    <h3>screens</h3>
                    <div className="screen_text_option">
                        <span>Sort by</span>

                        <div className="dropdown">


                            <NavLink to="#" className="btn dropdown-toggle" role="button" id="Link" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="screen_program_text">Program</span>
                            </NavLink>
                            <Link to="#"></Link>
                            <ul className="dropdown-menu" aria-labelledby="Link">
                                <li>
                                    <Link to="#" className="dropdown-item radius-top">
                                        Orientation
                                    </Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item">
                                        Location
                                    </Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item">
                                        Program
                                    </Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item radius-bottom">
                                        Date Added
                                    </Link>

                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="screen-scroll">
                    <div className="screen_gradient">
                        <h3>Digital Menu</h3>
                    </div>
                    <div className="check_button_screen">
                        <div className="row">
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_on.svg" title="Front Screen" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" links="/screens-1" />
                            </div>
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_off.svg" title="This is a sceen with a extreme long name …" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" />
                            </div>
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_on.svg" title="Front Screen" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" />
                            </div>
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_on.svg" title="Lounge Screen" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" />
                            </div>
                            <div className="col-md-3">
                                <Power images="../../../../assets/images/icon_no network.svg" image="../../../../assets/images/screen_power_off.svg" title="Digital Menu" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" links="/screen-6" />
                            </div>

                        </div>
                    </div>
                    <div className="screen_gradient">
                        <h3>Digital Menu</h3>
                    </div>
                    <div className="check_button_screen">
                        <div className="row">
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_on.svg" title="Front Screen" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" />
                            </div>
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_off.svg" title="This is a sceen with a extreme long name …" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" />
                            </div>
                            <div className="col-md-3">
                                <Power image="../../../../assets/images/screen_power_on.svg" title="Front Screen" heading="Lappert’s Ice Cream" headingList="Santa Monica, CA" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default TabScreen;