import React from 'react'
import { NavLink } from 'react-router-dom';

const CreateAccount = () => {
    return (
        <>
            <div className="user">
                <img src="../../../assets/images/PJLogo-Master@2x.png" alt="front-logo" className="img-fluid" />
                <div className="add-account">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Account</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Users</button>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <form id="addForm">
                                <div className="form-dark user_create_account">
                                    <label className="form-label ">Account Name</label>
                                    <input type="text" className="form-control" />
                                    <label className="form-label">Address</label>
                                    <input type="text" className="form-control" />
                                    <label className="form-label">Parent Account</label>
                                    {/* <div class="dropdown">
                                        <button class="btn" type="button" id="drop" data-bs-toggle="dropdown" aria-expanded="false">
                                            English (US)
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="drop">
                                            <li> <NavLink to="#" className="dropdown-item">
                                                English (US)</NavLink>
                                            </li>
                                            <li> <NavLink to="#" className="dropdown-item">
                                                English (UK)</NavLink>
                                            </li>
                                            <li> <NavLink to="#" className="dropdown-item">
                                                日本語</NavLink>
                                            </li>
                                            <li> <NavLink to="#" className="dropdown-item">
                                                Español (MEX)</NavLink>
                                            </li>

                                        </ul>
                                    </div> */}
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected>English (US)</option>
                                        <option value="1">English (US)</option>
                                        <option value="2">English (UK)</option>
                                        <option value="3">Español (MEX)</option>
                                    </select>
                                </div>
                                <div className="add-text">
                                    <div className="add-text-btn">
                                        <h4>
                                            Status
                                        </h4>
                                        <p>
                                            Active (Until 12/01/2020)
                                        </p>
                                    </div>
                                    <div className="add-text-btn">
                                        <h4>
                                            Term
                                        </h4>
                                        <p>
                                            2 Screens ($10.00 / Screen / Monthly)
                                        </p>
                                    </div>

                                    <div className="button-list">
                                        <button className="first">
                                            Digital Signage
                                        </button>
                                        <button className="second">
                                            Host
                                        </button>
                                        <button className="third">
                                            Advertiser
                                        </button>
                                    </div>
                                </div>
                                <div className="save">
                                    <button type="submit" className="submit">
                                        Cancel
                                    </button>
                                    <button type="submit" form="addForm" className="theme-button-2">
                                        save
                                    </button>

                                </div>
                            </form>

                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="user_create_account form-dark">
                                <label className="form-label ">Full Name</label>
                                <input type="text" className="form-control" />
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" />
                                <label className="form-label">Password</label>
                                <div className="pass-word">
                                    <input type="password" className="form-control" />
                                    <img src="../../../assets/images/icon_view password_off.svg" alt="icon_view password_off.svg" />
                                </div>

                                <label className="form-label">Role</label>
                                {/* <div class="dropdown">
                                    <button class="btn" type="button" id="drop" data-bs-toggle="dropdown" aria-expanded="false">
                                        English (US)
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="drop">
                                        <li> <NavLink to="#" className="dropdown-item">
                                            English (US)</NavLink>
                                        </li>
                                        <li> <NavLink to="#" className="dropdown-item">
                                            English (UK)</NavLink>
                                        </li>
                                        <li> <NavLink to="#" className="dropdown-item">
                                            日本語</NavLink>
                                        </li>
                                        <li> <NavLink to="#" className="dropdown-item">
                                            Español (MEX)</NavLink>
                                        </li>

                                    </ul>
                                </div> */}
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>English (US)</option>
                                    <option value="1">English (US)</option>
                                    <option value="2">English (UK)</option>
                                    <option value="3">Español (MEX)</option>
                                </select>
                                <div className="select-button mt-4">
                                    <button type="submit">
                                        Cancel
                                    </button>
                                    <NavLink to="/select-account" className="theme-button-2">
                                        Save
                                    </NavLink>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CreateAccount;
