import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import RightBarSelect from './components/RightBarSelect';
import PicturesTable from './components/PicturesTable'

const MediaLibraryVideos = () => {
    return (
        <>

            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Videos" />
                <RightBarSelect href="" heading="Upload Videos" />
            </MainLayout>
        </>
    )
};

export default MediaLibraryVideos;
