import React from 'react'
import { NavLink } from 'react-router-dom'

const ResetPassword = () => {
    return (
        <>
            <div className="sign-from">
                <div className="Welcome_page">
                    <div className="welcome">
                        <img src="../../../assets/images/PKJ Logo-Master@2x.png" alt="logo" className='img-fluid' />
                        <h1>Welcome!</h1>
                        <p className="outer-text">
                            Addulate is a company which create A.I Assisted, Targeted Digital Media On Public Facing Screens
                        </p>
                        <h3>
                            Easy to manage your screens
                        </h3>
                        <p className="context">
                            With our CMS, you can easily access, upload and manage your content anytime, anywhere.
                        </p>
                        <h3>
                            Data analysis
                        </h3>
                        <p className="context">
                            No matter what role you are, we show you the most important data visually.
                        </p>

                        <div class="btn-group dropend">
                            <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="../../../assets/images/united-states-of-america.png" alt="" />
                                English (US)
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/united-states-of-america.png" alt="united-states-of-america.png" />
                                        English (US)
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/united-kingdom.png" alt="united-kingdom" />
                                        English (UK)
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/china.png
                                       " alt="china" />
                                        日本語
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/european-union.png" alt="european-union" />
                                        Español (MEX)
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sign-in">
                    <div className="sign-1">
                        <h3 className="reset-pass">
                            Reset Your Password
                        </h3>

                        <div className="from form-dark">
                            <label className="form-label">Password</label>
                            <div className="pass-word">
                                <input type="password" className="form-control" />
                                <img src="../../../assets/images/icon_view password_off.svg" alt="icon_view password_off" />
                            </div>
                            <p>Minimum 10 characters</p>
                            <label className="form-label">Verify Password</label>
                            <div className="pass-word">
                                <input type="password" className="form-control" />
                                <img src="../../../assets/images/icon_view password_on.svg" alt="icon_view password_on" />
                            </div>

                        </div>

                        <div className="button-1">
                            <a href="/user-account" className="theme-button-2 mt-3">
                                Confirm
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ResetPassword
