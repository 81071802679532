import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import BoxEnterprise from './Component/BoxEnterprise';

const AccountDone = () => {
    return (
        <>
            <div className="user">
                <img src="../../../assets/images/PJLogo-Master@2x.png" alt="front-logo" className="img-fluid" />
                <div className="new-account">
                    <h3>
                        Lappert’s Enterprise
                    </h3>
                    <p>
                        Please select a account
                    </p>
                    <div className="row g-4 mt-2">
                        <div className="col-md-4">
                            <BoxEnterprise title="Lappert’s" subtitle="Ice Cream"
                                images="../../assets/images/btn_edit.svg" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="Kona " subtitle="Creamerys" images="../../assets/images/btn_edit.svg" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="Lappert’s " subtitle="Café" images="../../assets/images/btn_edit.svg" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="San Francisco " subtitle="Lappert’s" images="../../assets/images/btn_edit.svg" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="Lovely " subtitle="Cream" images="../../assets/images/btn_edit.svg" />
                        </div>

                    </div>
                    <div>
                        <Link to="/dashboard" className="theme-3 mt-5 px-5">
                            Done
                        </Link>
                    </div>

                </div>
            </div>
        </>
    )
};

export default AccountDone;
