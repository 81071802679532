import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import MainLayout from '../../../layout/MainLayout';
import MediaPodLeftSideBar from '../MediaPod/components/MediaPodLeftSideBar';

const Schedules1 = () => {
    return (
        <MainLayout>
            <MediaPodLeftSideBar />

            <div className="Schedules-restaurant-food">
                <div className="outer-restaurant-food">
                    <div className="list_food">
                        <span className="date">June 2020</span>
                        <i class="fa fa-caret-left"></i>
                        <span className="today">Today</span>
                        <i class="fa_2 fa fa-caret-right"></i>
                    </div>
                    <div className="Schedules-select">
                        <img src="../../../../assets/images/icon_schedule.svg" alt="" />
                        <span className="add-new">Add New Program</span>
                        <img src="../../../../assets/images/icon_delete.svg" alt="" />
                        <span className="new-delete">Delete</span>
                        <div className="dropdown">
                            <button class="btn new-program " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                New Program 1
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link to="#" className="dropdown-item">
                                        <div className="form-bg-dark">
                                            <input type="search" placeholder="Search" />
                                            <img src="../../../../assets/images/Icon_search.svg" alt="" />
                                        </div>
                                    </Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item">
                                        <div className="program-edit">
                                            <p>New Program 1</p>
                                            <img src="../../../../assets/images/icon_edit.svg" alt="" />
                                        </div>
                                    </Link>


                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item">
                                        <div className="program-edit food-items">
                                            <p>Food Promo</p>
                                        </div>
                                    </Link>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="table_list">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    Sun 16
                                </th>
                                <th>
                                    Mon 17
                                </th>
                                <th>
                                    Tue 18
                                </th>
                                <th>
                                    Wed 19
                                </th>
                                <th>
                                    Thu 20
                                </th>
                                <th>
                                    Fri 21
                                </th>
                                <th>
                                    Sat 22
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="timing">12 AM</td>
                                <td className="tool">


                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">1 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">2 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">3 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">4 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </MainLayout>
    )
};

export default Schedules1;