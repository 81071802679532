import React from 'react'
import MainLayout from '../../../layout/MainLayout';
import MediaPodLeftSideBar from '../MediaPod/components/MediaPodLeftSideBar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Schedules3 = () => {
    return (
        <MainLayout>
            <MediaPodLeftSideBar />
            <div className="Schedules-restaurant-food">
                <div className="outer-restaurant-food">
                    <div className="list_food">
                        <span className="date">June 2020</span>
                        <i class="fa fa-caret-left"></i>
                        <span className="today">Today</span>
                        <i class="fa_2 fa fa-caret-right"></i>
                    </div>
                    <div className="Schedules-select">
                        <img src="../../../../assets/images/icon_schedule.svg" alt="" />
                        <span className="add-new">Add New Program</span>
                        <img src="../../../../assets/images/icon_delete.svg" alt="" />
                        <span className="new-delete">Delete</span>
                        <select class="form-select">
                            <option selected>
                                New Program 1
                            </option>

                            <option>
                                Food Promo
                            </option>

                        </select>
                    </div>
                </div>
                <div className="table_list">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    Sun 16
                                </th>
                                <th>
                                    Mon 17
                                </th>
                                <th>
                                    Tue 18
                                </th>
                                <th>
                                    Wed 19
                                </th>
                                <th>
                                    Thu 20
                                </th>
                                <th>
                                    Fri 21
                                </th>
                                <th>
                                    Sat 22
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="timing">12 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">1 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">2 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">3 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowSpan="2" className="timing">4 AM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>

                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </MainLayout>
    )
};

export default Schedules3;