import { React, useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import MainLayout from '../../../layout/MainLayout';
import MediaPodLeftSideBar from '../MediaPod/components/MediaPodLeftSideBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const SchedulesEmpty = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <MainLayout>
                <MediaPodLeftSideBar />
                <div className="schedules popup">
                    <div className="outer-schedules-text">
                        <p>
                            You don’t have any program yet.
                        </p>
                        {/* Button trigger modal */}
                        {/* <button type="button" className="theme-button-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Create New Program
                        </button> */}
                        <button type="button" className="theme-button-2" onClick={handleShow}>
                            Create New Program
                        </button>
                        {/* <Button variant="primary" onClick={handleShow} className="theme-button-2">
                            Create New Program
                        </Button> */}


                        <div className="schedule-img">
                            <img src="../../../../assets/images/schedule_illustration_bg.svg" alt="schedule_illustration_bg" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className=" content-modal">
                        <div className=" ">
                            <Modal.Header>
                                <Modal.Title>
                                    <div className="form-bg-dark w-100">
                                        <label htmlFor="name">
                                            Name
                                        </label>
                                        <input type="text" placeholder="New Program 1" />
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            {/* <Modal.Body></Modal.Body> */}
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose} className="theme-3 cancel-btn">
                                    Cancel
                                </Button>
                                <Link to="/schedules1" onClick={handleClose} className="theme-button-2 create-btn-2">
                                    Create
                                </Link>
                                {/* <Button variant="primary" >

                        </Button> */}
                            </Modal.Footer>
                        </div>
                    </div>
                </Modal>


            </MainLayout>

        </>
    )
};



export default SchedulesEmpty;