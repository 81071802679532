import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const MediaPodLeftSideBar = () => {
    return (
        <>
            <div className="left-bar-media">
                <div className="form-bg-dark">
                    <div className="search-icon">
                        <input type="search" placeholder="Search" />
                        <img src="../../../../../assets/images/Icon_search.svg" alt="" />
                    </div>
                    <div className="media-outer-text">
                        <p>Media Pod</p>
                        <div className="media-from">
                            <span>
                                Sort by
                            </span>

                            <div className="dropdown">
                                <button class="btn dropdown-toggle" type="button" id="Button1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="first-media">
                                        Name
                                    </span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="Button1">
                                    <li>
                                        <Link to="#" className="dropdown-item radius-top">
                                            Action
                                        </Link>

                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item radius-bottom">
                                            Action2
                                        </Link>

                                    </li>

                                </ul>
                            </div>


                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th class="text-end">Date Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Restaurant Food</td>
                                <td class="text-end">Today</td>
                            </tr>
                            <tr>

                                <td>Bar screens</td>
                                <td class="text-end">@05/01/20</td>
                            </tr>
                            <tr>
                                <td>Restaurant Intro</td>
                                <td class="text-end">@04/18/20</td>
                            </tr>
                            <tr>
                                <td>test</td>
                                <td class="text-end">02/12/20</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="create-btn">
                        <NavLink to="#" className="theme-button-2">
                            Create New Media Pod
                        </NavLink>
                    </div>
                </div>

            </div>
        </>
    )
};

export default MediaPodLeftSideBar;
