import React from 'react';
import { Link } from 'react-router-dom';
import BoxEnterprise from './Component/BoxEnterprise';

const SelectAccount = () => {
    return (
        <>
            <div className="user">
                <img src="../../../assets/images/PJLogo-Master@2x.png" alt="front-logo" className="img-fluid" />
                <div className="new-account">
                    <h3>
                        Lappert’s Enterprise
                    </h3>
                    <p>
                        Please select a account
                    </p>
                    <div className="row g-4 mt-2">
                        <div className="col-md-4">
                            <BoxEnterprise title="Lappert’s" subtitle="Ice Cream" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="Kona " subtitle="Creamerys" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="Lappert’s " subtitle="Café" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="San Francisco " subtitle="Lappert’s" />
                        </div>
                        <div className="col-md-4">
                            <BoxEnterprise title="Lovely " subtitle="Cream" />
                        </div>
                        <div className="col-md-4">
                            <Link to="/account-done" className="add-create">
                                <img src="../../../assets/images/icon_add-2.svg" alt="" />
                                Add New
                            </Link>

                        </div>

                    </div>
                    <div>
                        <Link to="#" className="theme-3 mt-5">
                            Manage Accounts
                        </Link>

                    </div>
                </div>
            </div>
        </>
    )
};

export default SelectAccount;
