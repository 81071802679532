import React from 'react';
import { Link } from 'react-router-dom';


const RightBar = () => {
    return (
        <>
            <div className="right_list">
                <div className="screen-tab">
                    <div className="front_tabs">
                        <img src="../../../../../assets/images/icon_front_screen.svg" alt="" />
                        <span>Front Screen</span>
                    </div>
                    <div className="power">
                        <img src="../../../../../assets/images/screen_power_on.svg" alt="" />
                    </div>
                </div>
                <div className="tab-scroll">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Settings</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Attributes</button>

                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <form id="earlyAccessForm" action="">
                                <div className="first_tab_list">
                                    <h3>
                                        Orientation
                                    </h3>
                                    <div className="tab-screen">
                                        <label for="screen-1" className="label-check">
                                            <input type="radio" className="input-radio" id="screen-1" name="fav_language" value="screen-1" checked="checked" />
                                            <div className="circle-check"></div>
                                            Landscape
                                        </label>
                                        <label for="screen-2" className="label-check">
                                            <input type="radio" className="input-radio" id="screen-2" name="fav_language" value="screen-2" />
                                            <div className="circle-check"></div>
                                            Portrait
                                        </label>
                                    </div>

                                </div>
                                <div className="first_tab_list">
                                    <h3>Default Program</h3>
                                    <div className="form-bg-dark">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Digital Menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Current Program</h3>
                                    <div className="form-bg-dark">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Special Menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Media Unit</h3>
                                    <h3>MiBox S</h3>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Establishment Name</h3>
                                    <div className="tab_text">
                                        <span>Lappert’s Ice Cream</span>
                                        <img src="../../../../../assets/images/icon_edit.svg" alt="" />
                                    </div>

                                </div>
                                <div className="first_tab_list">
                                    <h3>Default Program</h3>
                                    <div className="form-bg-dark">
                                        <select className="form-select form-2" aria-label="Default select example">
                                            <option selected>Ice Cream / Sweet S…</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Address</h3>
                                    <div className="tab_text">
                                        <span>1547 Moana Drive </span>
                                        <img src="../../../../../assets/images/icon_edit.svg" alt="" />
                                        <h3>
                                            Kona, Hawai 03457
                                        </h3>
                                    </div>

                                </div>
                                <div className="first_tab_list">
                                    <h3>Time Zone</h3>
                                    <div className="form-bg-dark">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>PST</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Placement</h3>
                                    <div className="tab_text">
                                        <span>Above - Cashier</span>
                                        <img src="../../../../../assets/images/icon_edit.svg" alt="" />
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <form id="earlyAccessForm">
                                <div className="first_tab_list">
                                    <h3>Screen Info</h3>
                                    <div className="form-bg-dark">
                                        <input type="text" placeholder='Brand/Size/Resolution' />
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Estimated Daily <br />Foot Traffic</h3>
                                    <div className="form-bg-dark">
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3 className="hours-option">Hours of Operation</h3>
                                    <div className="check_box">
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox" />
                                        <label class="form-check-label" for="flexbox">
                                            Mon
                                        </label>
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox1" />
                                        <label class="form-check-label" for="flexbox1">
                                            Tue
                                        </label>
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox2" />
                                        <label class="form-check-label" for="flexbox2">
                                            Wed
                                        </label><br />
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox3" />
                                        <label class="form-check-label" for="flexbox3">
                                            Thu
                                        </label>
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox4" />
                                        <label class="form-check-label" for="flexbox4">
                                            Fri
                                        </label><br />
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox5" />
                                        <label class="form-check-label" for="flexbox5">
                                            Sat
                                        </label>
                                        <input class="form-check-input" type="checkbox" value="" id="flexbox6" />
                                        <label class="form-check-label" for="flexbox6">
                                            Sun
                                        </label><br />
                                        <div className="star-end">
                                            <p>Start: <span>12 : 00 AM</span></p>
                                            <p>Ends: <span>01 : 00 AM</span></p>
                                            <a href="#">
                                                <img src="../../../../../assets/images/icon_add_small_circle.svg" alt="" />
                                                Add a new set of time
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Demographics</h3>
                                    <div className="form-bg-dark">
                                        <select className="form-select form-3">
                                            <option selected>Gender/Age Group</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="first_tab_list">
                                    <h3>Theme</h3>
                                    <div className="form-bg-dark">
                                        <select className="form-select">
                                            <option selected></option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="screen-save-edit-btn">
                    <button type="submit" className="theme-3 me-2">
                        Cancel
                    </button>
                    <Link to="/screen-4" type="submit" form="earlyAccessForm" className="theme-button-2">
                        Save
                    </Link>
                </div>
            </div>
        </>
    )
};

export default RightBar