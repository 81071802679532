import React from 'react';
import { NavLink } from 'react-router-dom';

const RightBarSelect = (props) => {
    return (
        <>
            <div className="rightBar order-first order-lg-last">
                <h3>
                    <img src="../../../../../assets/images/icon_upload_grey.svg" alt="icon_upload_grey" className="pe-2" />
                    {props.heading}
                </h3>
                <div className="upload-box">
                    <p>Drag and drop your files here <br />or</p>
                    <NavLink className="theme-button-2" to={props.href}>
                        Select
                    </NavLink>

                </div>

                <button className="choose-file">
                    <img src="../../../../../assets/images/icon_new folder.svg" alt="icon_new folder" className="pe-2" />
                    New Folder
                </button>
            </div>
        </>
    )
};

export default RightBarSelect;
