import React from 'react';


const RightBar = ({ title, text, text2 }) => {
    return (
        <div className="right-bar order-first order-lg-last">
            <div className="color">
                <h3>
                    {title}
                </h3>
                <a href="#">
                    {text}
                </a>
                <a href="#">
                    {text2}
                </a>
            </div>
        </div>
    )
};

export default RightBar;