import React from 'react'
import { NavLink } from 'react-router-dom'

const SignIn = () => {
    return (
        <>
            <div className="sign-from">
                <div className="Welcome_page">
                    <div className="welcome">
                        <img src="../../../assets/images/PKJ Logo-Master@2x.png" alt="logo" className='img-fluid' />
                        <h1>Welcome!</h1>
                        <p className="outer-text">
                            Addulate is a company which create A.I Assisted, Targeted Digital Media On Public Facing Screens
                        </p>
                        <h3>
                            Easy to manage your screens
                        </h3>
                        <p className="context">
                            With our CMS, you can easily access, upload and manage your content anytime, anywhere.
                        </p>
                        <h3>
                            Data analysis
                        </h3>
                        <p className="context">
                            No matter what role you are, we show you the most important data visually.
                        </p>
                        {/* <select className="form-select">
                            <option selected>English (US)</option>
                            <option value="1">English (UK)</option>
                            <option value="2">日本語</option>
                            <option value="3">Español (MEX)</option>
                        </select> */}
                        <div class="btn-group dropend">
                            <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="../../../assets/images/united-states-of-america.png" alt="" />
                                English (US)
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/united-states-of-america.png" alt="united-states-of-america.png" />
                                        English (US)
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/united-kingdom.png" alt="united-kingdom" />
                                        English (UK)
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/china.png
                                       " alt="china" />
                                        日本語
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#" className="dropdown-item">
                                        <img src="../../../assets/images/european-union.png" alt="european-union" />
                                        Español (MEX)
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="sign-in">
                    <div className="tabs-items">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Sign Up</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Sign In</button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <form id="createForm">
                                <div className="from form-dark">
                                    <label className="form-label ">Full Name</label>
                                    <input type="text" className="form-control" />
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" />
                                    <label className="form-label">Password</label>
                                    <input type="password" className="form-control" />
                                    <p>Minimum 8 characters</p>
                                    <label className="form-label">Verify Password</label>
                                    <input type="password" className="form-control" />
                                </div>
                                <div className="button-submit">
                                    <NavLink to="#" className="google theme-3">
                                        <img src="../../../assets/images/google.png" alt="" />
                                        <span>
                                            Sign up with Google
                                        </span>
                                    </NavLink>
                                    <p>
                                        -or-
                                    </p>
                                    <button form="createForm" type="submit" className="theme-button-2 account_button_click">
                                        Create Account
                                    </button>

                                </div>
                            </form>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <form id="signInForm">
                                <div className="from form-dark">
                                    <label className="form-label">Email</label>
                                    <input type="text" className="form-control" />
                                    <label className="form-label">Password</label>
                                    <div className="pass-word">
                                        <input type="password" className="form-control" />
                                        <img src="../../../assets/images/icon_view password_off.svg" alt="icon_view password_off" />
                                    </div>
                                    <p>Minimum 10 characters</p>
                                </div>
                                <div className="check-out">
                                    <div>
                                        <input className="form-check-input" type="checkbox" id="gridCheck" />
                                        <label className="form-check-label" for="gridCheck" >
                                            Remember Me
                                        </label>
                                    </div>
                                    <NavLink to="/forgot-page">
                                        Forgot password?
                                    </NavLink>

                                </div>
                                <div className="button-submit">
                                    <NavLink to="#" className="google theme-3">
                                        <img src="../../../assets/images/google.png" alt="" />
                                        <span>
                                            Sign In with Google
                                        </span>
                                    </NavLink>
                                    <p>
                                        -or-
                                    </p>
                                    <button type="submit" form="signInForm" className="theme-button-2 account_button_click">
                                        Sign In
                                    </button>

                                </div>
                            </form>
                        </div>

                    </div>
                    {/* <div className="sign-1">
                        
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default SignIn
