import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import RightBarSelect from './components/RightBarSelect'
import { NavLink } from 'react-router-dom';

const MediaLibraryGridView = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />

                <div className="card-list">
                    <div className="outer-contact">
                        <h3>
                            Pictures
                        </h3>
                        <div className="card-icon">

                            <span>
                                View
                            </span>
                            <img src="../../../../../assets/images/icon_icon view.svg" alt="" />
                            <img src="../../../../../assets/images/icon_list view_active.svg" alt="" className="align-icon" />
                            <span>
                                Sort by
                            </span>

                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="change-color">
                                        Name
                                    </span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <NavLink to="#" className="dropdown-item">Action</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="#" className="dropdown-item">Another action</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="#" className="dropdown-item">Something else here</NavLink>

                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" />
                        <label class="form-check-label" for="gridCheck">
                            Check me out
                        </label>
                    </div>
                    <div className="file-select">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="file-1">
                                    <img src="../../../../assets/images/icon_folder.svg" alt="" className="img-fluid" />
                                    <p>
                                        Restaurant<br /> Food
                                        <i class="bi bi-pencil"></i>
                                    </p>
                                    <input class="form-check-input" type="checkbox" id="gridCheck" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="file-1">
                                    <img src="../../../../assets/images/icon_folder.svg" alt="" className="img-fluid" />
                                    <p>
                                        Restaurant<br /> Food
                                    </p>
                                    <input class="form-check-input" type="checkbox" id="gridCheck" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="file-images">
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" className="img-fluid" />
                                <h6>
                                    Photo 1.jpg
                                </h6>
                                <p>
                                    1920x1080
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="file-images">
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" className="img-fluid" />
                                <h6>
                                    Photo 1.jpg
                                </h6>
                                <p>
                                    1920x1080
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="file-images">
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" className="img-fluid" />
                                <h6>
                                    Photo 1.jpg
                                </h6>
                                <p>
                                    1920x1080
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="file-images">
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" className="img-fluid" />
                                <h6>
                                    Photo 1.jpg
                                </h6>
                                <p>
                                    1920x1080
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <RightBarSelect href="/media-library-videos" heading="Upload Pictures" />
            </MainLayout>

        </>
    )
};

export default MediaLibraryGridView;
