import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const RightBarMediaPod = () => {
    return (
        <>
            <div className="food_right_bar">
                <div className="upload-text">
                    <img src="../../../../assets/images/icon_upload_grey.svg" alt="" />
                    <h3>
                        Media Library
                    </h3>
                </div>
                <div className="menu_list_food">
                    <ul>
                        <li>
                            <Link to="#" className="active">All</Link>

                        </li>
                        <li>
                            <Link to="#">Pictures</Link>

                        </li>
                        <li>
                            <Link to="#">Videos</Link>

                        </li>
                        <li>
                            <Link to="#">Web</Link>

                        </li>
                        <li>
                            <Link to="#"> Apps</Link>

                        </li>


                    </ul>

                </div>
                <div className="list-food-menu">
                    <p>
                        Sort by
                    </p>
                    <div className="food_list">

                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="MenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>Name</span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="MenuButton1">
                                <li>
                                    <Link to="#" className="dropdown-item radius-top">Pizza</Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item">Burger</Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item">Momos</Link>

                                </li>
                                <li>
                                    <Link to="#" className="dropdown-item radius-bottom">Chow Mein</Link>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <table class="table">
                    <tbody>
                        <tr>

                            <td>
                                <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                Restaurant Food
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                Portrait Photos
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                Photo 1.jpg
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                Photo 2.jpg
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                Photo 3.png
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                Photo 4.png
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/Icon_videos.svg" alt="Icon_videos" />
                                Photo 1.mp4
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/Icon_videos.svg" alt="Icon_videos" />
                                Photo 2.mp4
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../../assets/images/Icon_videos.svg" alt="Icon_videos" />
                                Photo 3.mp4
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
};

export default RightBarMediaPod;
