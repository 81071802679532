import React, { useState, useEffect } from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import { NavLink } from 'react-router-dom';


const MediaLibraryApps3 = () => {
    const [status, setState] = useState(false);
    useEffect(() => {
        document.getElementById('open').addEventListener('click', () => setState(true));
        document.getElementById('close').addEventListener('click', () => setState(false));
    }, []);

    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <div className="card-list">
                    <div className="outer-contact">
                        <h3>
                            Apps
                        </h3>

                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Facebook
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="" className="img-fluid" />
                                        <p>

                                            @internationalThrillerWriters
                                        </p>
                                    </div>
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="" className="img-fluid" />
                                        <p>
                                            @saucedbbq&spirits
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Weather
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" className="img-fluid" />
                                        <p>
                                            San Francisco
                                        </p>
                                    </div>
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" className="img-fluid" />
                                        <p>
                                            New York
                                        </p>
                                    </div>
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="" className="img-fluid" />
                                        <p>
                                            Seattle
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Youtube
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="accordion-outer">
                                        <img src="../../../../assets/images/icon_youtube.svg" alt="" className="img-fluid" />
                                        <p>
                                            Rick Steves Holyland Tour
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="rightBar order-first order-lg-last">
                    <div className="title-url" >
                        <img src="../../../../assets/images/icon_add.svg" alt="" className="img-fluid" />
                        <h3>
                            Add New
                        </h3>
                    </div>

                    <div className="linking-icon">
                        <div className="row">
                            <h3>Apps</h3>
                            <div className="col-lg-4 col-6">
                                <div className="first" >
                                    <NavLink to="/media-library-apps">
                                        <img src="../../../../assets/images/icon_facebbook.svg" alt="facebook" className="img-fluid" />
                                        <p>
                                            Facebook
                                        </p>
                                    </NavLink>


                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first" id="open">

                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_weather.svg" alt="icon_weather" className="img-fluid" />
                                        <p>
                                            Weather
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_youtube.svg" alt="icon_youtube" className="img-fluid" />
                                        <p>
                                            Youtube
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_instagram@2x.png" alt="icon_instagram@2x" className="img-fluid" />
                                        <p>
                                            Instagram
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="#">
                                        <img src="../../../../assets/images/icon_twitter.svg" alt="icon_twitter" className="img-fluid" />
                                        <p>
                                            Twitter
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                            <div className="col-lg-4 col-6">
                                <div className="first">
                                    <NavLink to="/media-library-app-store">
                                        {/* <img src="../../../../assets/images/icon_apps (1).svg" alt="" /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                            <path id="icon_apps" d="M24,24H18V18h6v6Zm-9,0H9V18h6v6ZM6,24H0V18H6v6Zm18-9H18V9h6v6Zm-9,0H9V9h6v6ZM6,15H0V9H6v6ZM24,6H18V0h6V6ZM15,6H9V0h6V6ZM6,6H0V0H6V6Z" transform="translate(0 0)" fill="#E4E4ED" />
                                        </svg>
                                        <p>
                                            App Store
                                        </p>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={status ? "click-left-bar" : "close-left-bar"}>
                        <h3>Facebook Settings</h3>
                        <form id="submitForm">
                            <div className="form-bg-dark">
                                <label htmlFor="name">
                                    City
                                </label>
                                {/* <div class="dropdown">
                                    <button class="btn" type="button" id="drop" data-bs-toggle="dropdown" aria-expanded="false">
                                        °C-2
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="drop">
                                        <li> <NavLink to="#" className="dropdown-item">
                                            °C-3</NavLink>
                                        </li>
                                        <li> <NavLink to="#" className="dropdown-item">
                                            °F-1</NavLink>
                                        </li>
                                    </ul>
                                </div> */}
                                <select className="form-select" aria-label="Default select example">
                                    <option selected> °C-2</option>
                                    <option value="1">°C-3</option>
                                    <option value="2">°F-1</option>

                                </select>
                                <div class="btn-group">
                                    <button class="theme-cold">°C</button>
                                    <button class="theme-button-2">°F</button>
                                </div>

                            </div>
                            <div className="save-id-btn">
                                <button type="submit">
                                    Cancel
                                </button>
                                <NavLink to="#" form="submitForm" className="theme-button-2">
                                    Add
                                </NavLink>

                            </div>
                        </form>
                        <div className="close-btn" id="close">
                            <img src="../../../../assets/images/icon_close.svg" alt="" />
                        </div>

                    </div>
                </div>



            </MainLayout>
        </>
    )
};

export default MediaLibraryApps3;
