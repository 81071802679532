import React from 'react'

const Power = (props) => {
    return (
        <>
            <div className="outer_screen_check">
                <div className="start-btn">
                    <a href={props.links}>
                        <div className="screen-images">
                            <img src={props.images} alt="" />
                            <div className="start">
                                <img src={props.image} alt="" />
                            </div>
                        </div>
                        <p>{props.title}</p>
                        <p>{props.titleList}</p>

                    </a>
                </div>
                <h3>{props.heading}</h3>
                <p>{props.headingList}</p>
            </div>
        </>
    )
};

export default Power