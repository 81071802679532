import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar';


const MainLayout = ({ children, className }) => {
    return (
        <>
            <div className="wrapper">
                <Header />
                <NavigationBar />

                <div className={`wrapper-body ${className}`}>
                    {children}

                </div>

            </div>

        </>
    )
};

export default MainLayout;
