import React from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../../layout/MainLayout';
import TabScreen from './components/TabScreen';



const Screen4 = () => {
    return (
        <>
            <MainLayout>
                <TabScreen />
                <div className="right_list">
                    <div className="screen-tab">
                        <div className="front_tabs">
                            <img src="../../../../../assets/images/icon_front_screen.svg" alt="" />
                            <span>Front Screen</span>
                        </div>
                        <div className="power">
                            <img src="../../../../../assets/images/screen_power_on.svg" alt="" />
                        </div>
                    </div>
                    <div className="tab-scroll">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Settings</button>
                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Attributes</button>

                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <form id="earlyAccessForm" action="">
                                    <div className="first_tab_list">
                                        <h3>
                                            Orientation
                                        </h3>
                                        <h3>
                                            Landscape
                                        </h3>

                                    </div>
                                    <div className="first_tab_list text-start">
                                        <h3>Default Program</h3>
                                        <h3>
                                            Digital Menu
                                        </h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Current Program</h3>
                                        <h3>
                                            Special Menu
                                        </h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Media Unit</h3>
                                        <h3>MiBox S</h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Establishment Name</h3>
                                        <h3>
                                            Lappert’s Ice Cream
                                        </h3>

                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Business Type</h3>
                                        <h3>
                                            Ice Cream / Sweet Shop
                                        </h3>
                                    </div>
                                    <div className="first_tab_list text-end">
                                        <h3>Address</h3>
                                        <h3>
                                            1547 Moana Drive<br /> Kona, Hawai 03457
                                        </h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Time Zone</h3>
                                        <h3>
                                            PST
                                        </h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Placement</h3>
                                        <h3>
                                            Above - Cashier
                                        </h3>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <form id="earlyAccessForm" action="">
                                    <div className="first_tab_list">
                                        <h3>
                                            Screen Info
                                        </h3>
                                        <h3>
                                            LG 65” OLED 4K
                                        </h3>

                                    </div>
                                    <div className="first_tab_list text-start">
                                        <h3>Estimated Daily<br /> Foot Traffic</h3>
                                        <h3>
                                            50-200
                                        </h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Hours of Operation</h3>
                                        <h3>
                                            Mon, Wed, Thu, Fri<br /> 11:00 am - 10:30 pm
                                        </h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3></h3>
                                        <h3>Sat, Sun<br /> 9:00 am - 8:30 pm</h3>
                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Demographics</h3>
                                        <h3>
                                            Adult, Male, 18-45 <br />Adult, Female, 18-45<br /> Children 5-18
                                        </h3>

                                    </div>
                                    <div className="first_tab_list">
                                        <h3>Theme</h3>
                                        <h3>
                                            Family, Modern, Holidays
                                        </h3>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="screen-save-edit-btn">
                        <Link to="/screen-4" type="submit" form="earlyAccessForm" className="theme-button-2">
                            Edit
                        </Link>


                    </div>
                </div>

            </MainLayout>
        </>
    )
};

export default Screen4;