import React from 'react'

const BoxEnterprise = (props) => {
    return (
        <>
            <div className="box-inter">
                <ul>
                    <li>{props.title}</li>
                    <li>{props.subtitle}</li>

                </ul>
                <img src={props.images} alt="" className="img" />
            </div>
        </>
    )
};

export default BoxEnterprise;
