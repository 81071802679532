import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import { NavLink } from 'react-router-dom';

const MediaLibraryAddURL = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />

                <div className="card-list">

                    <div className="outer-contact">
                        <h3>
                            Web Pages
                        </h3>

                    </div>
                    <div className="list-table">
                        <table class="table">
                            <thead>
                                <tr className="first-child">
                                    <th>Name</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        <span className="px-2">New Folder</span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        <span className="px-2">Restaurant Food</span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <i class="bi bi-globe"></i>
                                        <a href="#" className="px-2">Promotion Page</a>

                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <i class="bi bi-globe"></i>
                                        <a href="#" className="px-2">Promotion Page</a>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <i class="bi bi-globe"></i>
                                        <a href="#" className="px-2">Homepage</a>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="rightBar order-first order-lg-last">
                    <h3>
                        Product Page
                    </h3>
                    <div className="url">
                        <h4>
                            url
                        </h4>
                        <div className="link-pen">
                            <a href="https://www.picklejar.com/product.html">
                                https://www.picklejar.com/<br />product.html

                            </a>
                            <img src="../../../../assets/images/icon_edit.svg" alt="" />
                        </div>
                    </div>

                    <div className="check-btn">
                        <button className="theme-3 me-3">
                            Delete
                        </button>
                        <NavLink to="#" className="theme-button delete_button">
                            Save
                        </NavLink>


                    </div>
                </div>
            </MainLayout>

        </>
    )
};

export default MediaLibraryAddURL;
