import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import { NavLink } from 'react-router-dom';

const Upload3 = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Pictures" />
                <div className="rightBar order-first order-lg-last">
                    <h3>
                        <img src="../../../../assets/images/icon_upload_grey.svg" alt="icon_upload_grey" className="pe-2" />
                        Upload Pictures
                    </h3>
                    <div className="upload-box">
                        <p>Drag and drop your files here <br />or</p>
                        <NavLink to="#" className="theme-button-2">
                            Select
                        </NavLink>

                    </div>

                    <button className="choose-file">
                        <img src="../../../../assets/images/icon_new folder.svg" alt="icon_new folder" className="pe-2" />
                        New Folder
                    </button>
                    <div className="check-btn">
                        <div className="pro-gress">
                            <p className="upload-text">Uploading 2 files… (1/2)</p>
                            <NavLink to="#" className="button">Retry</NavLink>

                        </div>
                        <div className="progress">
                            <div className="progress-bar w-25" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>

            </MainLayout>
        </>
    )
};

export default Upload3;
