import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import { NavLink } from 'react-router-dom';




const MediaLibraryURL = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />

                <div className="card-list">

                    <div className="outer-contact">
                        <h3>
                            Web Pages
                        </h3>

                    </div>
                    <div className="list-table">
                        <table class="table">
                            <thead>
                                <tr className="first-child">
                                    <th>Name</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        <span className="px-2">New Folder</span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        <span className="px-2">Restaurant Food</span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <i class="bi bi-globe"></i>
                                        <a href="#" className="px-2">Promotion Page</a>

                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <i class="bi bi-globe"></i>
                                        <a href="#" className="px-2">Promotion Page</a>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <i class="bi bi-globe"></i>
                                        <a href="#" className="px-2">Homepage</a>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="rightBar order-first order-lg-last">
                    <div className="title-url">
                        <img src="../../../../assets/images/icon_add.svg" alt="" className="img-fluid" />
                        <h3>
                            Add a Web Page
                        </h3>
                    </div>
                    <form id="addWebForm">
                        <div className="add_web form-bg-dark">
                            <label htmlFor="name">
                                Name
                            </label>
                            <input type="text" />
                            <label htmlFor="name">
                                URL
                            </label>
                            <input type="text" />
                            <label for="inputState" class="form-label">Folder (optional)</label>
                            {/* <div class="dropdown">
                                <button class="btn" type="button" id="drop" data-bs-toggle="dropdown" aria-expanded="false">
                                    Pictures
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="drop">
                                    <li> <NavLink to="#" className="dropdown-item">
                                        Pictures</NavLink>
                                    </li>
                                    <li> <NavLink to="#" className="dropdown-item">
                                        Restaurant Food</NavLink>
                                    </li>
                                </ul>
                            </div> */}
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Pictures</option>
                                <option value="1">Pictures</option>
                                <option value="2">Restaurant Food</option>
                            </select>

                            <div className="folder-btn">
                                <button>
                                    <img src="../../../../assets/images/icon_new folder.svg" alt="icon_new folder" className="pe-2" />
                                    New Folder
                                </button>

                                <NavLink to="/media-library-add-url" className="theme-button" form="addWebForm">
                                    Add
                                </NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </MainLayout>
        </>
    )
};

export default MediaLibraryURL;
