import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import { NavLink } from 'react-router-dom';


const AddTag = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Pictures" />
                <div className="rightBar order-first order-lg-last">
                    <div className="select">
                        <h3>
                            3 Files
                        </h3>
                        <label htmlFor="FName">
                            <img src="../../../../assets/images/icon_move.svg" alt="icon_move" />
                            <a href="/Move">
                                Move
                            </a>
                        </label>
                        <label htmlFor="copy">
                            <img src="../../../../assets/images/icon_copy.svg" alt="icon_copy.svg" />
                            Copy
                        </label>
                        <label htmlFor="Folder">
                            <img src="../../../../assets/images/icon_new folder.svg" alt="icon_new folder" />
                            New folder with selection
                        </label>
                        <div className="input-add">
                            <input type="text" class="form-control" placeholder="Edit Tags" />
                            <img src="../../../../assets/images/icon_tag.svg" alt="icon_tag" className="tag" />
                            <img src="../../../../assets/images/btn_tick_green.svg" alt="btn_tick_green" className="btn_tick_green" />
                        </div>

                    </div>
                    <div className="check-btn">
                        <NavLink to="/upload-2" className="theme-3">
                            Download
                        </NavLink>
                        <NavLink to="#" className="theme-4 ms-3"> Delete</NavLink>
                    </div>
                </div>
            </MainLayout>
        </>
    )
};

export default AddTag;
