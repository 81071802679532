import React from 'react';
import { NavLink } from 'react-router-dom';
import MainLayout from '../../../layout/MainLayout';

const MediaPod2 = () => {
    return (
        <>
            <MainLayout>
                <div className="media-page">
                    <div className="left_bar_media second-left">
                        <div className="form-bg-dark">
                            <label htmlFor="name">Name</label>
                            <input type="text" />
                            <p>Choose display format</p>
                        </div>
                        <div className="web-screen">
                            <label for="screen-1" className="label-check">
                                <input type="radio" className="input-radio" id="screen-1" name="fav_language" value="screen-1" checked="checked" />
                                <div className="circle-check"></div>
                                <img src="../../../../assets/images/tv_landscape.svg" alt="" />
                            </label><br />
                            <label for="screen-2" className="label-check">
                                <input type="radio" className="input-radio" id="screen-2" name="fav_language" value="screen-2" />
                                <div className="circle-check"></div>
                                <img src="../../../../assets/images/tv_portrait.svg" alt="" />
                            </label>
                        </div>
                        <div className="media_pod_create_button">
                            <NavLink to="#" className="theme-3 me-3 create_button  ">
                                Cancel
                            </NavLink>
                            <NavLink to="/media-pod-3" className="theme-button-2 create_button">
                                Create
                            </NavLink>
                        </div>

                    </div>
                    <div className="screen-media">

                        <div className="row align-items-center g-0">
                            <h3>
                                How It Works
                            </h3>

                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="media-heading outer-media">
                                    <img src="../../../../assets/images/img_playlist.svg" alt="mg_playlist" className="img-fluid" />
                                    <h4>
                                        Create a Media Pod
                                    </h4>
                                    <h5>
                                        1
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="media-heading">
                                    <img src="../../../../assets/images/img_schedule.svg" alt="img_schedule" className="img-fluid" />
                                    <h4>
                                        Add to Program
                                    </h4>
                                    <h5>
                                        2
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="media-heading">
                                    <img src="../../../../assets/images/img_deploy.svg" alt="img_deploy" className="img-fluid" />
                                    <div className="text-3">
                                        <h4>
                                            Deploy to Screens
                                        </h4>
                                        <h5>
                                            3
                                        </h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </MainLayout>
        </>
    )
};

export default MediaPod2;
