import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import MainLayout from '../../../layout/MainLayout';
import MediaPodLeftSideBar from './components/MediaPodLeftSideBar';
import RightBarMediaPod from './components/RightBarMediaPod';

const MediaPod5 = () => {
    return (
        <>
            <MainLayout>
                <MediaPodLeftSideBar />
                <div className="restaurant-food-media">
                    <div className="card-food-main">
                        <div className="food-1">
                            <span>Restaurant Food</span>
                            <img src="../../../../assets/images/icon_edit.svg" alt="" />
                        </div>

                        <div className="food-2">

                            <img src="../../../../assets/images/icon_copy.svg" alt="" />
                            <span>Duplicate</span>
                            <img src="../../../../assets/images/icon_delete.svg" alt="" />
                            <span className="food-color">Delete</span>
                        </div>
                    </div>
                    <div className="box-scroll">

                        <div className="select-food">
                            <div className="dropdown">
                                <button className="btn select-btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="../../../../assets/images/icon_fullscreen.svg" alt="" />
                                </button>
                                <ul class="dropdown-menu menu-list" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <Link to="#" className="dropdown-item radius-top">
                                            <img src="../../../../assets/images/icon_fullscreen.svg" alt="" />
                                            Full Screen
                                        </Link>

                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item ">
                                            <img src="../../../../assets/images/icon_landscape_split 1.svg" alt="" />
                                            Split Screen 1
                                        </Link>

                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item">
                                            <img src="../../../../assets/images/icon_landscape_split 2.svg" alt="" />
                                            Split Screen 2
                                        </Link>

                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item radius-bottom">
                                            <img src="../../../../assets/images/icon_landscape_split 3.svg" alt="" />
                                            Split Screen 3
                                        </Link>

                                    </li>

                                </ul>
                                <span>
                                    01:20
                                </span>
                            </div>
                            <div className="dropdown">
                                <button className="btn dot" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="../../../../assets/images/btn_more.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <NavLink to="#" className="dropdown-item radius-top">
                                            Duplicate
                                        </NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="#" className="dropdown-item radius-bottom">
                                            Delete Screen Format
                                        </NavLink>

                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div className="box-border box-setting">
                            <p>
                                Drag and drop files from the Media Library.
                            </p>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>

                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        Restaurant Food
                                    </td>
                                    <td>
                                        00:50
                                    </td>
                                    <td className="text-end">
                                        <img src="../../../../assets/images/icon_delete_blue.svg" alt="" className="delete-image" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        Portrait Photos
                                    </td>
                                    <td>
                                        00:10
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        Photo 1.jpg
                                    </td>
                                    <td>
                                        00:10
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        Photo 2.jpg
                                    </td>
                                    <td>
                                        00:10
                                    </td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <NavLink to="#" className="add-screen">
                        <img src="../../../../assets/images/icon_add_small_circle.svg" alt="" />
                        <span>
                            Add a new screen format
                        </span>
                    </NavLink>

                    <div className="food-button">
                        <p>Total: 00:10</p>
                        <NavLink to="/media-pod-7" className="theme-button-2">
                            Add to Program
                        </NavLink>

                    </div>
                </div>
                <RightBarMediaPod />
            </MainLayout>

        </>
    )
};

export default MediaPod5;
