import React from 'react'

const UserAccount = () => {
    return (
        <>
            <div className="user">
                <img src="../../../assets/images/PJLogo-Master@2x.png" alt="front-logo" className="img-fluid" />
                <div className="account">
                    <h3>
                        Lappert’s Enterprise
                    </h3>
                    <p>
                        Please select a account
                    </p>
                    <a href="/add-account" className="add-new-pages">
                        <img src="../../../assets/images/icon_add-2.svg" alt="" className="img-fluid plus" />
                        <p>
                            Add New
                        </p>


                    </a>


                </div>
            </div>
        </>
    )
};

export default UserAccount;
