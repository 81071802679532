import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import { NavLink } from 'react-router-dom';

const MediaLibraryPicturesDetail = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                <PicturesTable title="Pictures" />
                <div className="rightBar order-first order-lg-last">
                    <div className="outer-title-rightBar">
                        <h5>
                            Photo 1.jpg
                        </h5>
                        <p>
                            1.25 MB
                        </p>
                    </div>
                    <div className="outer-text">
                        <img src="../../../../assets/images/right-image.webp" alt="" className="img-fluid" />
                        <div className="details">
                            <h4>
                                Details
                            </h4>
                            <h4>
                                Last modified
                            </h4>
                            <p>
                                05/12/2020 10:16 PM
                            </p>
                            <h4>
                                Dimensions
                            </h4>
                            <p>
                                1920 x 1080
                            </p>
                            <label htmlFor="FName">
                                Tag
                            </label>
                            <div className="edit-input">
                                <input type="text" class="form-control" placeholder="Landscape" />
                                <img src="../../../../assets/images/icon_edit.svg" alt="" />
                            </div>
                            <h4>
                                Playlist
                            </h4>
                            <p>
                                N/A
                            </p>
                        </div>
                        <div className="check-btn">
                            <NavLink to="/media-library-new-folder" className="theme-3">
                                Download
                            </NavLink>
                            <NavLink to="#" className="theme-4 ms-3">Delete</NavLink>
                        </div>
                    </div>

                </div>
            </MainLayout>
        </>
    )
};

export default MediaLibraryPicturesDetail;
