import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn from './Pages/Welcome/SignIn';
import ForgotPage from './Pages/Welcome/ForgotPage';
import ResetPassword from './Pages/Welcome/ResetPassword';
import UserAccount from './Pages/Welcome/UserAccount';
import AddAccount from './Pages/Welcome/AddAccount';
import CreateAccount from './Pages/Welcome/CreateAccount';
import SelectAccount from './Pages/Welcome/SelectAccount'
import AccountDone from './Pages/Welcome/AccountDone';
import Dashboard from './Pages/DigitalSignage/Dashboard/Dashboard';
import MediaLibrary from './Pages/DigitalSignage/MediaLibrary/MediaLibrary';
import MediaLibraryPicturesDetail from './Pages/DigitalSignage/MediaLibrary/MediaLibraryPicturesDetail';
import MediaLibraryNewFolder from './Pages/DigitalSignage/MediaLibrary/MediaLibraryNewFolder';
import MultiSelect from './Pages/DigitalSignage/MediaLibrary/MultiSelect';
import Move from './Pages/DigitalSignage/MediaLibrary/Move';
import AddTag from './Pages/DigitalSignage/MediaLibrary/AddTag';
import Upload2 from './Pages/DigitalSignage/MediaLibrary/Upload2';
import Upload3 from './Pages/DigitalSignage/MediaLibrary/Upload3';
import MediaLibraryGridView from './Pages/DigitalSignage/MediaLibrary/MediaLibraryGridView';
import MediaLibraryVideos from './Pages/DigitalSignage/MediaLibrary/MediaLibraryVideos';
import MediaLibraryURL from './Pages/DigitalSignage/MediaLibrary/MediaLibraryURL';
import MediaLibraryAddURL from './Pages/DigitalSignage/MediaLibrary/MediaLibraryAddURL';
import MediaLibraryApps from './Pages/DigitalSignage/MediaLibrary/MediaLibraryApps';
import MediaLibraryApps3 from './Pages/DigitalSignage/MediaLibrary/MediaLibraryApps3';
import MediaLibraryAppStore from './Pages/DigitalSignage/MediaLibrary/MediaLibraryAppStore';
import MediaLibraryPicturesDetailv2 from './Pages/DigitalSignage/MediaLibrary/MediaLibraryPicturesDetailv2';
import MediaLibraryUpload from './Pages/DigitalSignage/MediaLibrary/MediaLibraryUpload';
import MediaPod1emptystate from './Pages/DigitalSignage/MediaPod/MediaPod1emptystate.jsx';
import MediaPod2 from './Pages/DigitalSignage/MediaPod/MediaPod2.jsx';
import MediaPod3 from './Pages/DigitalSignage/MediaPod/MediaPod3';
import MediaPod5 from './Pages/DigitalSignage/MediaPod/MediaPod5';
import MediaPod7 from './Pages/DigitalSignage/MediaPod/MediaPod7';
import SchedulesEmpty from './Pages/DigitalSignage/Schedules/SchedulesEmpty.jsx';
import Schedules1 from './Pages/DigitalSignage/Schedules/Schedules1';
import Schedules3 from './Pages/DigitalSignage/Schedules/Schedules3';
import ScreensEmpty from './Pages/DigitalSignage/Screens/ScreensEmpty.jsx';
import Screens1 from './Pages/DigitalSignage/Screens/Screens1';
import Screen6 from './Pages/DigitalSignage/Screens/Screen6';
import Screen4 from './Pages/DigitalSignage/Screens/Screen4';
const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/forgot-page" element={<ForgotPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/user-account" element={<UserAccount />} />
          <Route path="/add-account" element={<AddAccount />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/select-account" element={<SelectAccount />} />
          <Route path="/account-done" element={<AccountDone />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/media-library" element={<MediaLibrary />} />
          <Route path="/media-library-pictures-detail" element={<MediaLibraryPicturesDetail />} />
          <Route path="/media-library-new-folder" element={<MediaLibraryNewFolder />} />
          <Route path="/multi-select" element={<MultiSelect />} />
          <Route path="/move" element={<Move />} />
          <Route path="/add-tag" element={<AddTag />} />
          <Route path="/upload-2" element={<Upload2 />} />
          <Route path="/upload-3" element={<Upload3 />} />
          <Route path="/media-library-grid-view" element={<MediaLibraryGridView />} />
          <Route path="/media-library-videos" element={<MediaLibraryVideos />} />
          <Route path="/media-library-url" element={<MediaLibraryURL />} />
          <Route path="/media-library-add-url" element={<MediaLibraryAddURL />} />
          <Route path="/media-library-apps" element={<MediaLibraryApps />} />
          <Route path="/media-library-apps-3" element={<MediaLibraryApps3 />} />
          <Route path="/media-library-app-store" element={<MediaLibraryAppStore />} />
          <Route path="/media-library-pictures-detail-v2" element={<MediaLibraryPicturesDetailv2 />} />
          <Route path="/MediaLibraryUpload" element={<MediaLibraryUpload />} />
          <Route path="/media-pod-2" element={<MediaPod2 />} />
          <Route path="/media-pod-1empty-state" element={<MediaPod1emptystate />} />
          <Route path="/media-pod-3" element={<MediaPod3 />} />
          <Route path="/media-pod-5" element={<MediaPod5 />} />
          <Route path="/media-pod-7" element={<MediaPod7 />} />
          <Route path="/schedules-empty" element={<SchedulesEmpty />} />
          <Route path="/schedules1" element={<Schedules1 />} />
          <Route path="/schedules-3" element={<Schedules3 />} />
          <Route path="/screens-empty" element={<ScreensEmpty />} />
          <Route path="/screens-1" element={<Screens1 />} />

          <Route path="/screen-6" element={<Screen6 />} />
          <Route path="/screen-4" element={<Screen4 />} />
        </Routes>

      </BrowserRouter>

    </>
  )
}

export default App;
