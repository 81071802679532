import React from 'react';
import MainLayout from '../../../layout/MainLayout';

const ScreensEmpty = () => {
    return (
        <>
            <MainLayout>
                <div className="Screens_empty">
                    <p>You don’t have any screen yet.</p>
                    <a href="/screens-1" className="theme-button-1">
                        Register for new devices
                    </a>
                    <br />
                    <img src="../../../../assets/images/img_screens_empty.svg" alt="" className="img-fluid px-4" />
                </div>
            </MainLayout>
        </>
    )
};

export default ScreensEmpty;