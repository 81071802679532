import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import LeftSidebar from '../../../components/LeftSidebar';
import PicturesTable from './components/PicturesTable';
import { Link, NavLink } from 'react-router-dom';

const MediaLibraryPicturesDetailv2 = () => {
    return (
        <>
            <MainLayout>
                <LeftSidebar />
                {/* <PicturesTable title="Pictures" /> */}
                <div className="card-list">
                    <div className="outer-contact">
                        <h3>
                            Pictures
                        </h3>
                        <div className="card-icon">

                            <span>
                                View
                            </span>
                            <img src="../../../../../assets/images/icon_icon view.svg" alt="" />
                            <img src="../../../../../assets/images/icon_list view_active.svg" alt="" className="align-icon" />
                            <span>
                                Sort by
                            </span>

                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="change-color">
                                        Name
                                    </span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <Link to="#" className="dropdown-item bounder-top" >
                                            Action
                                        </Link>


                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item">
                                            Another action
                                        </Link>


                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item bounder-bottom">
                                            Something else here
                                        </Link>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="list-table">
                        <table class="table">

                            <thead>
                                <tr className="first-child">
                                    <th>Name</th>
                                    <th>Modified</th>
                                    <th>Dimensions</th>
                                    <th>Tog</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        <span className="px-2">Restaurant Food</span>
                                    </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_folder.svg" alt="icon_folder" />
                                        <span className="px-2">
                                            Portrait Photos
                                        </span>
                                    </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        <span className="px-2">
                                            Photo 1.jpg
                                        </span>
                                    </td>
                                    <td>
                                        05/12/2022 10:16PM
                                    </td>
                                    <td>1920*1080</td>
                                    <td>Landscape</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        <span className="px-2">
                                            Photo 1.jpg
                                        </span>
                                    </td>
                                    <td>
                                        05/12/2022 10:16PM
                                    </td>
                                    <td>1920*1080</td>
                                    <td>Landscape</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        <span className="px-2">
                                            Photo 1.jpg
                                        </span>
                                    </td>
                                    <td>
                                        05/12/2022 10:16PM
                                    </td>
                                    <td>1920*1080</td>
                                    <td>Landscape</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        <span className="px-2">
                                            Photo 1.jpg
                                        </span>
                                    </td>
                                    <td>
                                        05/12/2022 10:16PM
                                    </td>
                                    <td>1920*1080</td>
                                    <td>Landscape</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        <span className="px-2">
                                            Photo 1.jpg
                                        </span>
                                    </td>
                                    <td>
                                        05/12/2022 10:16PM
                                    </td>
                                    <td>1920*1080</td>
                                    <td>Landscape</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="../../../../assets/images/icon_image.svg" alt="icon_image" />
                                        <span className="px-2">
                                            Photo 1.jpg
                                        </span>
                                    </td>
                                    <td>
                                        05/12/2022 10:16PM
                                    </td>
                                    <td>1920*1080</td>
                                    <td>Landscape</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* Button trigger modal */}
                    <button type="button" class="modal-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path id="icon_add" d="M20,11.429H11.429V20H8.571V11.429H0V8.571H8.571V0h2.857V8.571H20v2.857" fill="#fff" />
                        </svg>
                    </button>
                    {/* Modal */}

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog modal-md">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        <img src="../../../../assets/images/icon_upload_grey.svg" alt="" />

                                        Upload Pictures
                                    </h5>

                                </div>
                                <div class="modal-body">
                                    <div className="body-outer">
                                        <p>
                                            Drag and drop your files here<br />or
                                        </p>

                                        <NavLink to="#" className="theme-button-2">
                                            Select
                                        </NavLink>

                                    </div>
                                </div>
                                <div class="modal-footer">

                                    <button type="button" class="close-modal" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="rightBar order-first order-lg-last">
                    <div className="outer-title-rightBar">
                        <h5>
                            Photo 1.jpg
                        </h5>
                        <p>
                            1.25 MB
                        </p>
                    </div>
                    <div className="outer-text">
                        <img src="../../../../assets/images/right-image.webp" alt="" className="img-fluid" />
                        <div className="details">
                            <h4>
                                Details
                            </h4>
                            <h4>
                                Last modified
                            </h4>
                            <p>
                                05/12/2020 10:16 PM
                            </p>
                            <h4>
                                Dimensions
                            </h4>
                            <p>
                                1920 x 1080
                            </p>
                            <label htmlFor="FName">
                                Tag
                            </label>
                            <div className="edit-input">
                                <input type="text" class="form-control" placeholder="Landscape" />
                                <img src="../../../../assets/images/icon_edit.svg" alt="" />
                            </div>
                            <h4>
                                Playlist
                            </h4>
                            <p>
                                N/A
                            </p>
                        </div>
                        <div className="check-btn">
                            <NavLink to="/media-library-new-folder" className="theme-3">
                                Download
                            </NavLink>
                            <NavLink to="#" className="theme-4 ms-3">Delete</NavLink>
                        </div>
                    </div>

                </div>

            </MainLayout>
        </>
    )
};

export default MediaLibraryPicturesDetailv2;
