import React from 'react';
import { NavLink } from "react-router-dom";

const NavigationBar = () => {
    return (
        <>
            <div className="second-menu">
                <div className="heading">
                    <h3>
                        Dashboard - Digital Signage

                    </h3>

                </div>
                <div className="menu_second_bar">
                    <input type="checkbox" id="right" />
                    <label htmlFor="right" className="click">
                        <i class="fa fa-align-justify"></i>
                    </label>
                    <ul>

                        <li>
                            <NavLink to="/dashboard" className="active-2">
                                <img src="../../assets/images/Icon_dash_active.svg" alt="Icon_dash_active" />
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/media-library" >
                                <img src="../../assets/images/icon_library.svg" alt="" />
                                Media Library
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/media-pod-1empty-state">
                                <img src="../../assets/images/icon_playlist.svg" alt="" />

                                Media Pod
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/schedules-empty">
                                <img src="../../assets/images/icon_schedule.svg" alt="" />
                                Programs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/screens-empty">
                                <img src="../../assets/images/icon_screens.svg" alt="" />
                                Screens
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="#">
                                <img src="../../assets/images/icon_apps (1).svg" alt="" />

                                Apps
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
};

export default NavigationBar;
