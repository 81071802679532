import React from 'react'
import { NavLink } from 'react-router-dom'

const Sidebar = () => {
    return (
        <>
            <div className="left-side">
                <ul>
                    <li className="bor">
                        <NavLink to="/media-library" className="active-1">
                            <img src="../../assets/images/icon_pictures_active.svg" alt="" />
                            Pictures
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/media-library-videos">
                            <img src="../../assets/images/Icon_videos.svg" alt="" />
                            Videos
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/media-library-url">
                            <img src="../../assets/images/Icon_web.svg" alt="" />
                            Web Pages
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/media-library-apps">
                            <img src="../../assets/images/icon_apps.svg" alt="" />

                            Apps
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Sidebar