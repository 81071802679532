import React from 'react'
import { NavLink } from 'react-router-dom';

const BoxDark = (props) => {
    return (
        <>
            <div className="total-box">
                <NavLink to="#">
                    <p> {props.title}</p>
                    <div className="center">
                        <h4>
                            {props.text}
                        </h4>
                    </div>
                </NavLink>

            </div>
        </>
    )
};

export default BoxDark;
