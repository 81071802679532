import React from 'react';
import MainLayout from '../../../layout/MainLayout';
import TabScreen from './components/TabScreen';



const Screen6 = () => {
    return (
        <>
            <MainLayout>
                <TabScreen />
                <div className="right_list">
                    <div className="screen-tab">
                        <div className="front_tabs">
                            <img src="../../../../../assets/images/icon_front_screen.svg" alt="" />
                            <span>Digital Menu</span>
                        </div>
                        <div className="power">
                            <img src="../../../../assets/images/icon_no network.svg" alt="" className="wifi-net" />
                            <span>Connect</span>
                            <img src="../../../../../assets/images/screen_power_off.svg" alt="" className="" />
                        </div>
                    </div>
                    <form id="earlyForm">
                        <div className="tab-scroll">
                            <div className="digital-lock">
                                <p>
                                    Type in the 6-digital code shown on the screen to activate
                                </p>
                                <div className="code">
                                    <p>1</p>
                                    <p>0</p>
                                    <p>3</p>
                                    <p>8</p>
                                    <p>6</p>
                                    <p>1</p>
                                </div>

                            </div>
                            <div className="code-text">
                                <h3>
                                    Media Unit
                                </h3>
                                <h3>
                                    MiBox S
                                </h3>
                            </div>
                            <div className="code-text">
                                <h3>
                                    Establishment Name
                                </h3>
                                <h3>
                                    Lappert’s Ice Cream
                                </h3>
                            </div>
                            <div className="code-text">
                                <h3>
                                    Business Type
                                </h3>
                                <h3>
                                    Ice Cream / Sweet Shop
                                </h3>
                            </div>
                            <div className="code-text">
                                <h3>
                                    Address
                                </h3>
                                <h3>
                                    1547 Moana Drive<br /> Kona, Hawai 03457
                                </h3>
                            </div>
                            <div className="code-text">
                                <h3>
                                    Time Zone
                                </h3>
                                <h3>
                                    PST
                                </h3>
                            </div>
                            <div className="code-text">
                                <h3>
                                    Placement
                                </h3>
                                <h3>
                                    Above - Cashier
                                </h3>
                            </div>
                        </div>
                    </form>
                    <div className="screen-save-edit-btn">
                        <button type="submit" form="earlyForm" className="theme-button-2">
                            Edit
                        </button>
                    </div>
                </div>

            </MainLayout>
        </>
    )
};

export default Screen6;